import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import styles from '../styles/Global'

export default function AkahuAuth() {
  const [params] = useSearchParams()
  const error = params.get('error')
  const code = params.get('code')

  useEffect(() => {
    if (code) {
      window.location.replace(`myexpense://accounts?code=${code}`)
    }
  }, [code])

  useEffect(() => {
    if (error) {
      window.location.replace(`myexpense://accounts?error=${error}`)
    }
  }, [error])

  return (
    <>
      <div className={`${styles.boxWidth} sm:px-8 px-28 bg-transparent absolute`}>
        <Navbar variant="light" />
      </div>
      <div
        className={`min-h-screen flex justify-center items-center p-16 sm:p-8 bg-primary banner`}
      >
        {code && (
          <div>
            <h3 className={`${styles.h3Text} ${styles.whiteText} text-center`}>
              Connecting Your Account
            </h3>
          </div>
        )}
      </div>
    </>
  )
}
