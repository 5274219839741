import React from 'react'
import styles from '../styles/Global'

const Privacy = () => {
  return (
    <div className={`p-8`}>
      <h1 className={`${styles.h1Text} my-8`}>Privacy Policy</h1>
      <h2 className={styles.h2Text}>Your privacy</h2>
      <p className={styles.pText}>
        We respect your privacy and understand that it is an important concern, especially for a
        personal finance app like MyExpense. Parse Digital Limited. (“Parse Digital”), the creator
        of MyExpense, is committed to protecting the privacy of all of our users. This Privacy and
        Security Policy explains how MyExpense protects the information provided to us through the
        MyExpense web and mobile applications.
      </p>
      <h2 className={styles.h2Text}>
        1. Your Personal Information is never sold or rented, to anyone, for any reason, at any
        time.
      </h2>
      <p className={styles.pText}>
        Personal information refers to information that can identify you, such as your name, e-mail
        address, location, online identifiers. It can also include any data you provide while using
        MyExpense, like your spending history. This information is collected when you use our mobile
        apps. Your information will only be used:
      </p>
      <ul className={`list-disc px-16 mb-4`}>
        <li>
          To provide a service, such as providing access to your Accounts, and Transaction History
          through the MyExpense mobile apps;
        </li>
        <li>
          To improve your experience using the application, such as pre-filling your information for
          better user experience;
        </li>
        <li>To communicate with you regarding the MyExpense service;</li>
        <li>
          To be used in the aggregate in a non-personally identifiable way to improve MyExpense;
        </li>
        <li>To enforce our Terms of Use;</li>
        <li>As otherwise set forth in this Privacy and Security Policy.</li>
      </ul>
      <h2 className={styles.h2Text}>2. We keep your information safe.</h2>
      <p className={styles.pText}>
        All of the information submitted to MyExpense is secured using SSL in a secure datacenter.
      </p>
      <h2 className={styles.h2Text}>3. We don’t sell or rent your information to third parties.</h2>
      <p className={styles.pText}>
        Parse Digital does NOT sell or rent your contact information or other personally
        identifiable information to third parties. Parse Digital may only share Personal Information
        with third-party contractors when:
      </p>
      <ul className={`list-disc px-16 mb-4`}>
        <li>You provide your consent.</li>
        <li>
          We need to share your information to provide a product or service you have requested.
        </li>
        <li>
          • We need to share your information to companies who work on Parse Digital’s behalf, who
          are bound by the same privacy restrictions as described in this Privacy and Security
          Policy. These companies include Google Cloud Platform (data storage), SendGrid (email),
          Zendesk (customer support).
        </li>
        <li>To comply with law enforcement.</li>
      </ul>
      <h2 className={styles.h2Text}>
        4. You can always access or remove your information, and opt out of hearing from us.
      </h2>
      <p className={styles.pText}>
        You are entitled to know whether we hold information about you and, if we do, to have access
        to that information and require it to be correct if it is inaccurate. You may also ask us to
        remove your personally identifiable information; once you ask us, we’ll delete it from our
        database in 30 days. Your personally identifiable information will also be completely
        removed from any logs and backups within 12 months, or after the period we’re legally
        required to keep records to comply with laws like anti-money laundering or financial
        regulations.
      </p>
      <p className={styles.pText}>
        You can request to access your information or have it deleted by sending us an e-mail at
        info@myexpense.io, Subject: MyExpense Privacy Policy from the e-mail address associated with
        your MyExpense account. You can also authorize someone else to submit a request on your
        behalf the same way.
      </p>
      <p className={styles.pText}>
        When we receive a request, we will verify that the information in the request matches what
        we have in our records. For example, if you send the request from the email address on your
        MyExpense account, that can be used to verify that you own the MyExpense account. Otherwise,
        we may ask for extra information to verify and confirm your account ownership. You may opt
        out of receiving marketing e-mail communications at any time by clicking on the unsubscribe
        link included at the bottom of our e-mails or sending us mail or e-mail at the address
        below.
      </p>
      <h2 className={styles.h2Text}>
        5. We are not responsible for what you do on public websites!
      </h2>
      <p className={styles.pText}>
        Remember that any comments you write on our blog posts, our social media updates or our
        forums can be viewed and used by anyone on the internet. Parse Digital is not responsible
        for the privacy or security of any information disclosed on these public forums.
      </p>
      <h2 className={styles.h2Text}>
        6. Your Non-Personal Information may be used to improve our service.
      </h2>
      <p className={styles.pText}>
        Like many other websites and mobile apps, myexpense.io and the MyExpense apps may collect
        technical information, such as what browser you’re using or how much time you spend on a
        certain page or screen, to help us understand how you use MyExpense. Similarly, our site
        also employs an internet technology called “cookies.” Cookies are small files which save
        information about your visit to our site. None of this information is used to identify you
        personally, but is used in the aggregate to help us improve the site’s performance, design,
        and stability.
      </p>
      <h2 className={styles.h2Text}>7. Your data is stored at datacenters in Australia.</h2>
      <p className={styles.pText}>
        You understand and agree that we store and process your personal information on computers
        located in Australia, and that by providing any information to us, you consent to the
        transfer of such information to Australia.
      </p>
      <h2 className={styles.h2Text}>8. Have questions? Contact us.</h2>
      <p className={styles.pText}>
        We may decide to change this Privacy and Security Policy, but if we do we’ll post the
        changes here. If you have any questions about this Privacy and Security Policy you can
        contact us at:
      </p>
      <h4>Mohi Khalili, CPO</h4>
      <p>Parse Digital Limited</p>
      <p>Level 1/46 Stanley Street</p>
      <p>Parnell, Auckland 1010</p>
      <p>email: info@myexpense.io</p>
      <p></p>
      <p>Last Revised: Oct 2, 2022.</p>
    </div>
  )
}

export default Privacy
