import React, { useEffect, useState, useMemo } from 'react'
import styles from '../styles/Global'
import Navbar from '../components/Navbar'
import { useSearchParams } from 'react-router-dom'
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'
import { auth } from '../firebase'

export default function ResetPassword() {
  const [params] = useSearchParams()
  const mode = params.get('mode')
  const oobCode = params.get('oobCode')

  const initialValues = { password: '' }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [email, setEmail] = useState()
  const [message, setMessage] = useState()

  useMemo(() => {
    if (oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then(email => {
          setEmail(email)
        })
        .catch(e => {
          // check the error code and setMessage accordingly
          switch (e.message) {
            case 'Firebase: Error (auth/api-key-not-valid.-please-pass-a-valid-api-key.).':
              setMessage('There was an error verifying your request. Invalid Key.')
              break

            case 'Firebase: Error (auth/expired-action-code).':
              setMessage('The reset password token has been used or expired.')
              break
            default:
              break
          } //Firebase: Error (auth/expired-action-code).
        })
    }
  }, [oobCode])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && formSubmitted) {
      submitForm()
      setFormSubmitted(false)
    }
  }, [formErrors, formSubmitted])

  const submitForm = async () => {
    if (email) {
      try {
        await confirmPasswordReset(auth, oobCode, formValues.password)
        setMessage('Your password has been reset successfully.')
      } catch (error) {
        console.log(error)
        setMessage('There was an error resetting your request.')
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormErrors(validate(formValues))
    setFormSubmitted(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validate = values => {
    let errors = {}
    if (!values.password) {
      errors.password = 'Cannot be blank'
    } else if (values.password.length < 8) {
      errors.password = 'Password must be more than 8 characters'
    }
    return errors
  }

  return (
    <>
      <div className={`${styles.boxWidth} sm:px-8 px-28 bg-transparent absolute`}>
        <Navbar variant="light" />
      </div>
      <div
        className={`min-h-screen flex justify-center items-center p-16 sm:p-8 bg-primary banner`}
      >
        <div className="flex-col sm:w-3/4 md:w-3/4 lg:w-2/3 max-w-xl">
          {(!mode || !oobCode) && (
            <div
              className={`flex items-center
      ${styles.boxClass}
      w-11/12 sm:w-full minmd:w-3/4 justify-center`}
            >
              <h3 className={`${styles.h3Text} ${styles.errorText} text-center`}>
                There was an error processing your request. Please try resetting your password
                again. No authorization code provided.
              </h3>
            </div>
          )}
          {email && (
            <div className={`justify-center flex-col`}>
              <h2 className={`${styles.h3Text} ${styles.whiteText} text-center`}>
                Reset Password for: {email}
              </h2>
              <div className="w-full flex-col my-4">
                <form onSubmit={handleSubmit} noValidate>
                  <div className="w-full flex-col">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={formValues.password}
                      onChange={handleChange}
                      className={[
                        `sm:w-full w-3/5 outline-none rounded sm:my-4
                  mr-4 py-3 px-3 bg-white shadow text-sm text-gray-700 placeholder-gray-400
                  focus:outline-none focus:shadow-outline`,
                        formErrors.password && styles.errorText
                      ]}
                      placeholder="New Password"
                    />
                    <button
                      type="submit"
                      class="rounded-lg sm:w-full px-8
                       py-2 bg-primary text-purple-100
                       hover:bg-purple-600 duration-300"
                    >
                      CONFIRM
                    </button>
                  </div>
                  {formErrors.password && (
                    <p
                      className={`
                      w-full sm:text-center
                      text-error my-2 minmd:my-3
                      font-IBMPlex font-light text-sm
                      minmd:text-sm minlg:text-sm minmd:leading-12
                      `}
                    >
                      {formErrors.password}
                    </p>
                  )}
                </form>
              </div>
            </div>
          )}
          {message && (
            <div
              className={`flex items-center
      ${styles.boxClass}
      w-11/12 sm:w-full minmd:w-3/4 justify-center`}
            >
              <h3 className={`${styles.h3Text} ${styles.whiteText} text-center`}>{message}</h3>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
