import React from 'react'

import assets from '../assets'
import styles from '../styles/Global'

const FeatureCard = ({ iconUrl, iconText }) => (
  <div className={styles.featureCard}>
    <img src={iconUrl} alt="icon" className={styles.featureImg} />
    <p className={styles.featureText}>{iconText}</p>
  </div>
)

const Features = () => {
  return (
    <div className={`${styles.section} ${styles.bgPrimary} banner03`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.whiteText}`}>Make Expense Tracing Easy</h1>
          <p className={`${styles.pText} ${styles.whiteText}`}>
            To reach your financial goals you need to track and know how you spend your money. Our
            aim is to make it easier for you.
          </p>
        </div>

        <div className={styles.flexWrap}>
          <FeatureCard iconUrl={assets.finance3} iconText="Track" />
          <FeatureCard iconUrl={assets.finance2} iconText="Analyze" />
          <FeatureCard iconUrl={assets.finance1} iconText="Save" />
        </div>
      </div>
    </div>
  )
}

export default Features
