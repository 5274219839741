import React from 'react'
import ReactDOM from 'react-dom'
import './firebase'
import './index.css'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import Privacy from './pages/Privacy'
import ResetPassword from './pages/ResetPassword'
import AkahuAuth from './pages/AkahuAuth';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: 'privacy-policy',
    element: <Privacy />
  },
  {
    path: 'terms-and-conditions',
    element: <div>T&C</div>
  },
  {
    path: 'reset-password',
    element: <ResetPassword />
  },
  {
    path: 'akahu-auth',
    element: <AkahuAuth />
  }
])

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
)
