import { Features, SectionWrapper, Navbar } from './components'
import assets from './assets'
import styles from './styles/Global'
import { Link } from 'react-router-dom'

const App = () => {
  return (
    <>
      <div className={`${styles.boxWidth} sm:px-8 px-28 bg-transparent absolute`}>
        <Navbar variant="light" />
      </div>
      <SectionWrapper
        title="Expense Tracking & Budget Management Done Right"
        description="Track your expenses, find out your spending habits and areas you can save money."
        showBtn
        mockupImg={assets.homeHero}
        banner="banner"
        padding="sm:py-28 md:pt-28 pt-20"
      />
      <SectionWrapper
        title="Intuitive User Interface"
        description="Add your expenses manually in 3 steps or connect your bank account to automate the process*"
        mockupImg={assets.homeCards}
        reverse
      />
      <Features />
      <SectionWrapper
        title="Dark Mode"
        description="Add your expenses before going to bed and use the dark mode to have less strain on the eyes."
        mockupImg={assets.feature}
        reverse
      />
      {/*<SectionWrapper*/}
      {/*  title="Creative way to showcase the store"*/}
      {/*  description="The app contains two screens. The first screen lists all NFTs while the second one shows the details of a specific NFT."*/}
      {/*  mockupImg={assets.mockup}*/}
      {/*  banner="banner02"*/}
      {/*/>*/}
      {/*<Download />*/}

      <div className="px-3 p-16 justify-center items-center bg-primary text-center banner04">
        <div className={`mt-4 `}>
          <Link to="privacy-policy" className={`${styles.whiteText} mt-5 mr-10`}>
            Privacy Policy
          </Link>
          <Link to="terms-and-conditions" className={`${styles.whiteText} mb-5 ml-10`}>
            Terms & Conditions
          </Link>
        </div>
        <p className={`${styles.whiteText} text-sm mt-4`}>
          Parse Digital Limited. All rights reserved.
        </p>
        <p className={`${styles.whiteText} text-xs`} mt-10>
          * Some features are for premium users only and are paid features. Some features are coming
          soon and not available at launch.
        </p>
      </div>
    </>
  )
}

export default App
