import homeHero from './myexpense_hero.png'
import homeCards from './home_cards.png'
import feature from './feature.png'
import finance1 from './finance01.png'
import finance2 from './finance02.png'
import finance3 from './finance03.png'
import googlePlay from './app_download.png'
import logoLight from './myexpense-logo-light.png'
import logoDark from './myexpense-logo-v1.png'
import close from './close.svg'
import menu from './menu.svg'

export default {
  homeHero,
  homeCards,
  feature,
  googlePlay,
  finance1,
  finance2,
  finance3,
  logoLight,
  logoDark,
  close,
  menu
}
